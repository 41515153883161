import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { WrapRootElementBrowserArgs } from 'gatsby';
import * as React from 'react';

import * as styles from './wrapRootElement.module.scss';

const isBrowser = typeof window !== 'undefined';

export const WrapRootElement: React.FC<WrapRootElementBrowserArgs> = ({ element }) => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  if (isBrowser) {
    const firebaseConfig = {
      apiKey: 'AIzaSyDoqdSCpfTFxZ64JGPhBlv8sVuOVei1gl8',
      authDomain: 'wavesix-2d4f7.firebaseapp.com',
      projectId: 'wavesix-2d4f7',
      storageBucket: 'wavesix-2d4f7.appspot.com',
      messagingSenderId: '760640332794',
      appId: '1:760640332794:web:41dde4e5c12c543703fa43',
      measurementId: 'G-0GCF1DNM15',
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const analytics = getAnalytics(app);
  }

  return <div className={styles.root}>{element}</div>;
};
